import React from "react"

import { Imgs } from "../../../@types/common"
import InfoImg from "../../../blocks/infoImg/InfoImg"
import info1Img from "../../../assets/images/asr-tts/info1.svg"
import info2Img from "../../../assets/images/asr-tts/info2.svg"

const images: Imgs = {
  info1Img: info1Img,
  info2Img: info2Img,
}

export const AsrTtsSectionThird = ({ data, ...props }: { className: string; data: any }) => {
  return <InfoImg data={data?.content?.Section3} images={images} {...props} />
}

export default AsrTtsSectionThird
