import React from "react"

import { Imgs } from "../../../@types/common"
import InfoBlock from "../../../blocks/info-cards"
import card1Img from "../../../assets/images/asr-tts/card1.svg"
import card2Img from "../../../assets/images/asr-tts/card2.svg"
import card3Img from "../../../assets/images/asr-tts/card3.svg"
import card4Img from "../../../assets/images/asr-tts/card4.svg"
import card5Img from "../../../assets/images/asr-tts/card5.svg"
import card6Img from "../../../assets/images/asr-tts/card6.svg"

const images: Imgs = {
  card1Img: card1Img,
  card2Img: card2Img,
  card3Img: card3Img,
  card4Img: card4Img,
  card5Img: card5Img,
  card6Img: card6Img,
}

export const AsrTtsSectionSecond = ({ data, ...props }: { className: string; data: any }) => {
  const preparedData = {
    data: {
      content: {
        dataset: {
          infoBlock: data?.content?.Section2,
        },
      },
    },
  }

  return <InfoBlock data={preparedData} images={images} {...props} />
}

export default AsrTtsSectionSecond
