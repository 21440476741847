import React from "react"

import InfoAction from "../../../blocks/infoAction/InfoAction"
import actionImg from "../../../assets/images/asr-tts/action.svg"
import { Imgs } from "../../../@types/common"

const images: Imgs = {
  actionImg: actionImg,
}

export const AsrTtsSectionForth = ({ data, ...props }: { className: string; data: any }) => {
  return <InfoAction data={data?.content?.Section4} images={images} {...props} />
}

export default AsrTtsSectionForth
