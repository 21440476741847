import React from "react"

import asrImg from "../../../assets/images/asr-tts/asr.svg"
import ttsImg from "../../../assets/images/asr-tts/tts.svg"
import SliderBanner from "../../../blocks/sliderBanner/SliderBanner"

export interface Imgs {
  [k: string]: string
}

const images: Imgs = {
  asrImg: asrImg,
  ttsImg: ttsImg,
}

export const AsrTtsSectionFirst = ({ data, ...props }: { className: string; data: any }) => {
  return <SliderBanner data={data?.content?.Section1} images={images} {...props} />
}

export default AsrTtsSectionFirst
