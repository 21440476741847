import React from "react"
import { graphql } from "gatsby"

import Layout from "../layout/layout"
import SEO from "../components/SEO/seo"
import AsrTtsSections from "../pagesStructures/asrTts/asrTtsSections"

const AsrTts = ({ data, pageContext }: { data?: any; pageContext: any }) => {
  const pageContent = data.strapiAsrTts.content.find((el: any) => {
    return el.language === pageContext.lang
  })

  return (
    <Layout bgCustomClass="aboutUs">
      <SEO title={pageContent.content.title} description="ASR TTS page" />
      <AsrTtsSections data={{ ...pageContent, pageContext: pageContext }} />
    </Layout>
  )
}

export default AsrTts

export const query = graphql`
  query AsrTts($url: String) {
    strapiAsrTts(url: { eq: $url }) {
      content {
        content {
          title
          Section1 {
            heading
            subheading
            imageList {
              imgAlt
              imgSrc
              switchBtnText
              mainBtnLink
              mainBtnText
            }
          }
          Section2 {
            cards {
              buttonHref
              buttonText
              imgSrc
              text
              title
            }
            heading
          }
          Section3 {
            heading
            imgAlt
            imgSrc
            paragraphs
          }
          Section4 {
            btnLink
            btnText
            heading
            imgAlt
            imgSrc
            paragraph
          }
        }
        language
      }
    }
  }
`
