import React from "react"

import AsrTtsSectionFirst from "./asrTtsSections/AsrTtsSectionFirst"
import AsrTtsSectionForth from "./asrTtsSections/AsrTtsSectionForth"
import AsrTtsSectionSecond from "./asrTtsSections/AsrTtsSectionSecond"
import AsrTtsSectionThird from "./asrTtsSections/AsrTtsSectionThird"

const sections = [
  { component: (key: number, className: string, data: any) => <AsrTtsSectionFirst data={data} className={className} key={key} /> },
  { component: (key: number, className: string, data: any) => <AsrTtsSectionSecond data={data} className={className} key={key} /> },
  { component: (key: number, className: string, data: any) => <AsrTtsSectionThird data={data} className={className} key={key} /> },
  { component: (key: number, className: string, data: any) => <AsrTtsSectionForth data={data} className={className} key={key} /> },
]

const AsrTtsSections = ({ data }: { data: any }) => {
  return <>{sections.map(({ component }, i) => component(i, `sova-section sova-section-asrTts_${i}`, data))}</>
}

AsrTtsSections.displayName = "AsrTtsSections"

export default AsrTtsSections
